<template>
  <div v-if="!loading">
    <Card :title="title" :disabled="!editMode">
      <template #close>
        <a @click.prevent="$router.go(-1)">
          <v-btn icon>
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </a>
      </template>
      <template #actions>
        <v-btn v-if="$route.params.id" @click="editMode = !editMode">
          <span>{{ editMode ? "Görüntüle" : "Düzenle" }}</span>
        </v-btn>
      </template>
      <v-form ref="form" v-model="isFormValid" lazy-validation>
        <v-card flat class="pa-4">
          <h2 class="mb-4">Bilgiler</h2>
          <v-row>
            <v-col cols="4">
              <v-text-field
                hide-details="auto"
                v-model="currentRegistrationPeriod.name"
                label="Kayıt Dönemi Adı"
                :rules="[
                  (v) => !!v || 'Kayıt dönemi adı alanı boş bırakılamaz',
                  (v) =>
                    v?.length >= 3 ||
                    'Kayıt dönemi adı alanı en az 3 karakterden oluşmalıdır',
                ]"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-menu
                ref="startDateMenu"
                v-model="startDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formattedStartDate"
                    label="Baslangıç Tarihi"
                    v-bind="attrs"
                    :rules="[
                      (v) => !!v || 'Başlangıç tarihi alanı boş bırakılamaz',
                    ]"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="currentRegistrationPeriod.starts_on"
                  no-title
                  @input="startDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="4">
              <v-menu
                ref="endDateMenu"
                v-model="endDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formattedEndDate"
                    label="Bitiş Tarihi"
                    v-bind="attrs"
                    :rules="[
                      (v) => !!v || 'Bitiş tarihi alanı boş bırakılamaz',
                    ]"
                    required
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="currentRegistrationPeriod.ends_on"
                  no-title
                  @input="endDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="d-flex justify-content-end">
                <v-btn class="mr-3" color="error" @click="reset"> İptal </v-btn>
                <v-btn
                  class="mr-3"
                  color="primary"
                  :disabled="!isFormValid"
                  @click="save"
                >
                  Kaydet
                </v-btn>
                <v-btn
                  color="primary"
                  :disabled="!isFormValid"
                  @click="saveAndClose"
                >
                  Kaydet ve Kapat
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-form>
    </Card>
  </div>
</template>

<script>
import Card from "@/view/content/components/Card";
import { mapActions, mapGetters } from "vuex";
import {
  GET_REGISTRATION_PERIOD,
  CREATE_REGISTRATION_PERIOD,
  UPDATE_REGISTRATION_PERIOD,
} from "@/core/services/store/definitions/registration-periods.module";

export default {
  name: "RegistrationPeriodEdit",
  components: { Card },
  data() {
    return {
      editMode: false,
      currentRegistrationPeriod: {},
      loading: !!this.$route.params.id,
      isFormValid: true,
      startDateMenu: false,
      endDateMenu: false,
    };
  },
  async mounted() {
    if (this.$route.params.id) {
      try {
        const registrationPeriod = await this.getRegistrationPeriod(
          this.$route.params.id
        );
        this.currentRegistrationPeriod = Object.assign({}, registrationPeriod);
      } catch (error) {
        this.$router.push({ name: "def-registration-periods" });
      } finally {
        this.loading = false;
      }
    }
    this.$route.query.editable
      ? (this.editMode = true)
      : (this.editMode = false);
  },
  methods: {
    ...mapActions([
      GET_REGISTRATION_PERIOD,
      CREATE_REGISTRATION_PERIOD,
      UPDATE_REGISTRATION_PERIOD,
    ]),
    formatDate(date) {
      if (!date) return null;

      return this.$moment(date).format("DD/MM/YYYY");
    },
    async save() {
      if (this.$route.params.id) {
        try {
          const registrationPeriod = JSON.parse(
            JSON.stringify(this.currentRegistrationPeriod)
          );
          const data = {
            ...registrationPeriod,
            id: this.$route.params.id,
          };
          await this.updateRegistrationPeriod(data);
          this.$toast.success("Kayıt dönemi güncellendi", {
            position: "bottom-right",
            duration: 2000,
          });
        } catch (error) {
          this.stopExecution = true;
          this.$toast.error(error[0].detail, {
            position: "bottom-right",
            duration: 2000,
          });
        }
      } else {
        try {
          const registrationPeriod = await this.createRegistrationPeriod(
            this.currentRegistrationPeriod
          );
          this.$router.push({
            name: "def-registration-periods-edit",
            params: {
              id: registrationPeriod.id,
            },
            query: { editable: true },
          });
          this.$toast.success("Kayıt dönemi yaratıldı", {
            position: "bottom-right",
            duration: 2000,
          });
        } catch (error) {
          this.stopExecution = true;
          this.$toast.error(error[0].detail, {
            position: "bottom-right",
            duration: 2000,
          });
        }
      }
    },
    async saveAndClose() {
      await this.save(false);
      if (this.isFormValid && !this.stopExecution)
        this.$router.push({ name: "def-registration-periods" });
      this.stopExecution = false;
    },
    reset() {
      if (this.$route.params.id) {
        this.currentRegistrationPeriod = Object.assign(
          {},
          this.registrationPeriod
        );
      } else {
        this.currentRegistrationPeriod = {};
      }
      this.$router.push({ name: "def-registration-periods" });
    },
  },
  computed: {
    ...mapGetters(["registrationPeriod"]),
    title() {
      if (this.$route.params.id) {
        if (this.editMode) return "Kayıt Dönemi Düzenle";
        return "Kayıt Dönemi Detay";
      }
      return "Kayıt Dönemi Yaratma";
    },
    formattedStartDate() {
      return this.formatDate(this.currentRegistrationPeriod.starts_on);
    },
    formattedEndDate() {
      return this.formatDate(this.currentRegistrationPeriod.ends_on);
    },
  },
};
</script>
