var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.loading)?_c('div',[_c('Card',{attrs:{"title":_vm.title,"disabled":!_vm.editMode},scopedSlots:_vm._u([{key:"close",fn:function(){return [_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.$router.go(-1)}}},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)],1)]},proxy:true},{key:"actions",fn:function(){return [(_vm.$route.params.id)?_c('v-btn',{on:{"click":function($event){_vm.editMode = !_vm.editMode}}},[_c('span',[_vm._v(_vm._s(_vm.editMode ? "Görüntüle" : "Düzenle"))])]):_vm._e()]},proxy:true}],null,false,839984946)},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-card',{staticClass:"pa-4",attrs:{"flat":""}},[_c('h2',{staticClass:"mb-4"},[_vm._v("Bilgiler")]),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"hide-details":"auto","label":"Kayıt Dönemi Adı","rules":[
                (v) => !!v || 'Kayıt dönemi adı alanı boş bırakılamaz',
                (v) =>
                  v?.length >= 3 ||
                  'Kayıt dönemi adı alanı en az 3 karakterden oluşmalıdır',
              ],"required":""},model:{value:(_vm.currentRegistrationPeriod.name),callback:function ($$v) {_vm.$set(_vm.currentRegistrationPeriod, "name", $$v)},expression:"currentRegistrationPeriod.name"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{ref:"startDateMenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Baslangıç Tarihi","rules":[
                    (v) => !!v || 'Başlangıç tarihi alanı boş bırakılamaz',
                  ]},model:{value:(_vm.formattedStartDate),callback:function ($$v) {_vm.formattedStartDate=$$v},expression:"formattedStartDate"}},'v-text-field',attrs,false),on))]}}],null,false,2879930108),model:{value:(_vm.startDateMenu),callback:function ($$v) {_vm.startDateMenu=$$v},expression:"startDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.startDateMenu = false}},model:{value:(_vm.currentRegistrationPeriod.starts_on),callback:function ($$v) {_vm.$set(_vm.currentRegistrationPeriod, "starts_on", $$v)},expression:"currentRegistrationPeriod.starts_on"}})],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{ref:"endDateMenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Bitiş Tarihi","rules":[
                    (v) => !!v || 'Bitiş tarihi alanı boş bırakılamaz',
                  ],"required":""},model:{value:(_vm.formattedEndDate),callback:function ($$v) {_vm.formattedEndDate=$$v},expression:"formattedEndDate"}},'v-text-field',attrs,false),on))]}}],null,false,2123198848),model:{value:(_vm.endDateMenu),callback:function ($$v) {_vm.endDateMenu=$$v},expression:"endDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.endDateMenu = false}},model:{value:(_vm.currentRegistrationPeriod.ends_on),callback:function ($$v) {_vm.$set(_vm.currentRegistrationPeriod, "ends_on", $$v)},expression:"currentRegistrationPeriod.ends_on"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('v-btn',{staticClass:"mr-3",attrs:{"color":"error"},on:{"click":_vm.reset}},[_vm._v(" İptal ")]),_c('v-btn',{staticClass:"mr-3",attrs:{"color":"primary","disabled":!_vm.isFormValid},on:{"click":_vm.save}},[_vm._v(" Kaydet ")]),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.isFormValid},on:{"click":_vm.saveAndClose}},[_vm._v(" Kaydet ve Kapat ")])],1)])],1)],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }